import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from 'styled-components';
import Banner from "../components/banner";
import ContactForm from "../components/form";


const RequestEstimatePage = () => (
  <Layout>
    <Seo title="Request an estimate" description="Take the next step and request an estimate from ThowardMedia." />

    <Banner title="Request an estimate"  />

    <Wrapper>
      <p>Have a project in mind and would like an estimate? <br/> Fill out the form below and we'll get back to you.</p>
 
      <ContactForm formid="mdovrkzv" messagelabel="Message">
        <input type="text" name="name" placeholder="Enter your name" required/>
        <input type="email" name="email" placeholder="Enter your email address" required />
        <textarea name="message" placeholder="Enter your message here" required />

        <label class="services-label">Services requested:</label>
        <i>Check all that apply.</i>
        <div class="services-wrapper">
          <label for="web-dev">
            <input type="checkbox" id="web-dev" name="web-dev" value="Web dev" />
            Website Development
          </label>
          <label for="web-design">
            <input type="checkbox" id="web-design" name="web-design" value="Web design" />
            Website Design
          </label>
          <label for="web-maintenance">
            <input type="checkbox" id="web-maintenance" name="web-maintenance" value="Web maintenance" />
            Website Maintenance
          </label>
          <label for="web-support">
            <input type="checkbox" id="web-support" name="web-support" value="Web support" />
            Website Support
          </label>
        </div>

        <input type="text" name="_gotcha" style={{display: `none`}} />
      </ContactForm>
    </Wrapper>

  </Layout>
)

export default RequestEstimatePage

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  
  p {
    text-align: center;
  }

  i {
    font-size: 12px;
  }


  .services-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    label {
      flex-basis: 50%;
    }
  }


  @media(max-width: 980px) {
    padding: 0 20px;
  }
`